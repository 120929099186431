import React, { useState, useContext, useEffect } from "react";
import userContext from "../../context/userContext";

import CountDown from "../Count Down Timer/CountDown";

function LeasingCard({ id, title, register }) {
	const [selected, setSelected] = useState(false);
	const [dueDate, setDueDate] = useState();
	const { rentalContract } = useContext(userContext);

	const getReturnDueDate = async () => {
		//  onRentTokenids.map((Tokenid) =>
		await rentalContract.methods
			.book(id)
			.call()
			.then((res) => {
				// let data = res.dueDate;
				setDueDate(parseInt(res.dueDate));
			})
			.catch((error) => {
				console.log("error for due date", error);
			});
		// );
	};
	useEffect(() => {
		getReturnDueDate();
		// console.log("RESPONSE", dueDate);
	}, []);

	let nftPrice;

	if (id < 14 || id > 114) {
		nftPrice = "0.26";
	} else {
		nftPrice = "0.13";
	}

	return (
		<div className={`card cardsdesign ${selected ? "dark" : "light"}`}>
			{id >= 5 && id < 15 ? (
				<video
					className="video"
					width="auto"
					height="auto"
					controls
					autoPlay
					muted>
					<source
						src={`https://scratch.mypinata.cloud/ipfs/QmPEUnjWSt8jaJ4h1RbDwFGvvyLk8nj2C4Dm2fTHcbxy8C/${id}.mp4`}
						type="Video/mp4"
					/>
				</video>
			) : (
				<img
					className="card-img-top nftimage"
					src={`https://scratch.mypinata.cloud/ipfs/QmPEUnjWSt8jaJ4h1RbDwFGvvyLk8nj2C4Dm2fTHcbxy8C/${id}.jpg`}
					alt="Your NFTs"
				/>
			)}
			<div className="card-body">
				<div className=" text-center justify-content-between">
					<div className="text-center">
						<h5 className="card-text">{title}</h5>
						<p className="card-text">
							{nftPrice} ETH | Token-id : {id}
						</p>
					</div>
					<div className="text-center ">
						<div>
							<p className="" style={{ color: "green" }}>
								Roboto Minting
							</p>
						</div>
					</div>
					<input
						type="checkbox"
						className="checkbox"
						value={id}
						{...register("id")}
						onChange={(e) => {
							if (e.target.checked) {
								setSelected(true);
							} else {
								setSelected(false);
							}
						}}></input>
				</div>
				{dueDate ? (
					<div className="d-flex justify-content-around">
						<p>Return Date:</p>
						<CountDown dueDate={dueDate} />
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
}

export default LeasingCard;
