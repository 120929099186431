import React, { useState } from "react";
import { Link } from "react-router-dom";
import Facebook from "../../components/Social/FacebookIcon.svg";
import Instagram from "../../components/Social/IGIcon.svg";
import LinkedInIcon from "../../components/Social/LinkedInIcon.svg";
import TwitterIcon from "../../components/Social/TwitterIcon.svg";
import "./Footer.css";
const Footer = () => {
	const [scroll, setScroll] = useState(false);

	const scrollfn = () => {
		setScroll(!scroll);
	};
	return (
		<div
			onMouseEnter={scrollfn}
			onMouseLeave={scrollfn}
			className={scroll ? "footerouter" : "footerouter2"}>
			<div className={scroll ? "footercon topscroll" : "footercon2 botscroll"}>
				<div className={scroll ? "footerbar" : "footerbar2"}></div>
				<div
					className={
						scroll ? "w-100 d-flex footerCol align-items-center " : "d-none"
					}>
					<div className=" w-100 d-flex footerIcons  align-items-start justify-content-center">
						<Link className="mx-2" to="#in">
							<img src={Instagram} width={"40px"} alt="df" />
						</Link>
						<Link className="mx-2" to="#sm">
							<img src={Facebook} width={"40px"} alt="df" />
						</Link>
						<Link className="mx-2" to="#sm">
							<img src={TwitterIcon} width={"40px"} alt="df" />
						</Link>
						<Link className="mx-2" to="#sm">
							<img src={LinkedInIcon} width={"40px"} alt="df" />
						</Link>
					</div>
					<div className="w-100 d-flex align-items-center justify-content-center">
						<p className="copyright text-white">
							<strong className="copyrightIcon">&copy;</strong> COPYRIGHT 2022 ,
							ALL RIGHTS RESERVED
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
