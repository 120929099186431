import React, { useState, useRef, useEffect } from "react";

const CountDown = ({ dueDate }) => {
	const [countDays, setCountDays] = useState();
	const [countHours, setCountHours] = useState();
	const [countMinutes, setCountMinutes] = useState();
	const [countSeconds, setCountSeconds] = useState();

	let interval = useRef();
	const startTimer = () => {
		// const returnDate = dueDate.toString();
		const countDate = new Date(dueDate * 1000).getTime();

		interval = setInterval(() => {
			const now = new Date().getTime();
			const Distance = countDate - now;

			const days = Math.floor(Distance / (1000 * 60 * 60 * 24));
			const hours = Math.floor(
				(Distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			const minutes = Math.floor((Distance % (1000 * 60 * 60)) / (1000 * 60));
			const seconds = Math.floor((Distance % (1000 * 60)) / 1000);

			if (Distance < 0) {
				clearInterval(interval.current);
			} else {
				setCountDays(days.toLocaleString("en-US", { minimumIntegerDigits: 2 }));
				setCountHours(
					hours.toLocaleString("en-US", { minimumIntegerDigits: 2 })
				);
				setCountMinutes(
					minutes.toLocaleString("en-US", { minimumIntegerDigits: 2 })
				);
				setCountSeconds(
					seconds.toLocaleString("en-US", { minimumIntegerDigits: 2 })
				);
			}
		}, 1000);
	};
	// console.log(selected);
	// console.log("price", id);

	useEffect(() => {
		startTimer();
		const cleanUp = interval.current;
		return () => {
			clearInterval(cleanUp);
		};
	});
	return (
		<div className="d-flex">
			<p>
				{countDays}:{countHours}:{countMinutes}:{countSeconds}
			</p>
		</div>
	);
};

export default CountDown;
