import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import Facebook from "../../components/Social/FacebookIcon.svg";
import Instagram from "../../components/Social/IGIcon.svg";
import LinkedInIcon from "../../components/Social/LinkedInIcon.svg";
import TwitterIcon from "../../components/Social/TwitterIcon.svg";
import "../../components/Footer/Footer.css";
import Lines from "../../assets/GIF/Lines.gif";
import "./policy.css";

const Policy = () => {
	const [scroll, setScroll] = useState(false);

	const scrollfn = () => {
		setScroll(!scroll);
		console.log("enter");
	};
	return (
		<>
			<div className="text-white policy container-fluid pb-5">
				<div className="test container-fluid">
					<div className="my-5 container text-left">
						<h1>Privacy Policy for Scratch Smart</h1>
					</div>
					<div className="text-left container policycss">
						<p>
							At{" "}
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							, accessible from{" "}
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							, one of our main priorities is the privacy of our visitors. This
							Privacy Policy document contains types of information that is
							collected and recorded by
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							and how we use it.
						</p>
						<p>
							If you have additional questions or require more information about
							our Privacy Policy, do not hesitate to contact us.
						</p>
						<p>
							This Privacy Policy applies only to our online activities and is
							valid for visitors to our website with regards to the information
							that they shared and/or collect in{" "}
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							. This policy is not applicable to any information collected
							offline or via channels other than this website. Our Privacy
							Policy was created with the help of the Free Privacy Policy
							Generator.
						</p>
						<h2>1. Consent</h2>

						<p>
							By using our website, you hereby consent to our Privacy Policy and
							agree to its terms.
						</p>

						<h2>2. Information we collect</h2>

						<p>
							The personal information that you are asked to provide, and the
							reasons why you are asked to provide it, will be made clear to you
							at the point we ask you to provide your personal information.
						</p>

						<p>
							If you contact us directly, we may receive additional information
							about you such as your name, email address, phone number, the
							contents of the message and/or attachments you may send us, and
							any other information you may choose to provide.
						</p>
						<p>
							When you register for an Account, we may ask for your contact
							information, including items such as name, company name, address,
							email address, and telephone number.
						</p>
						<h2>3. How we use your information</h2>
						<p>
							We use the information we collect in various ways, including to:
						</p>
						<div className="px-4">
							<ul>
								<li>Provide, operate, and maintain our website</li>
								<li>Improve, personalize, and expand our website</li>
								<li>Understand and analyze how you use our website</li>
								<li>
									Develop new products, services, features, and functionality
								</li>
								<li>
									Communicate with you, either directly or through one of our
									partners, including for customer service, to provide you with
									updates and other information relating to the website, and for
									marketing and promotional purposes
								</li>
								<li>Send you emails</li>
								<li>Find and prevent fraud</li>
							</ul>
						</div>
						<br />
						<h2>4. Log Files</h2>

						<p>
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							follows a standard procedure of using log files. These files log
							visitors when they visit websites. All hosting companies do this
							and a part of hosting services' analytics. The information
							collected by log files include internet protocol (IP) addresses,
							browser type, Internet Service Provider (ISP), date and time
							stamp, referring/exit pages, and possibly the number of clicks.
							These are not linked to any information that is personally
							identifiable. The purpose of the information is for analyzing
							trends, administering the site, tracking users' movement on the
							website, and gathering demographic information.
						</p>
						<h2>5. Cookies and Web Beacons</h2>

						<p>
							Like any other website,{" "}
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							uses 'cookies'. These cookies are used to store information
							including visitors' preferences, and the pages on the website that
							the visitor accessed or visited. The information is used to
							optimize the users' experience by customizing our web page content
							based on visitors' browser type and/or other information.
						</p>
						<h2>6. Advertising Partners Privacy Policies</h2>
						<p>
							You may consult this list to find the Privacy Policy for each of
							the advertising partners of{" "}
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							.
						</p>

						<p>
							Third-party ad servers or ad networks uses technologies like
							cookies, JavaScript, or Web Beacons that are used in their
							respective advertisements and links that appear on{" "}
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							, which are sent directly to users' browser. They automatically
							receive your IP address when this occurs. These technologies are
							used to measure the effectiveness of their advertising campaigns
							and/or to personalize the advertising content that you see on
							websites that you visit.
						</p>
						<p>
							Note that https://scratch-app.com/ has no access to or control
							over these cookies that are used by third-party advertisers.
						</p>
						<br />
						<h2>7. Third Party Privacy Policies</h2>

						<p>
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							's Privacy Policy does not apply to other advertisers or websites.
							Thus, we are advising you to consult the respective Privacy
							Policies of these third-party ad servers for more detailed
							information. It may include their practices and instructions about
							how to opt-out of certain options.
						</p>
						<p>
							You can choose to disable cookies through your individual browser
							options. To know more detailed information about cookie management
							with specific web browsers, it can be found at the browsers'
							respective websites.
						</p>
						<h2>
							8. CCPA Privacy Rights (Do Not Sell My Personal Information)
						</h2>
						<p>
							Under the CCPA, among other rights, California consumers have the
							right to:
						</p>

						<div className="px-4">
							<ul>
								<li>
									Request that a business that collects a consumer's personal
									data disclose the categories and specific pieces of personal
									data that a business has collected about consumers.
								</li>

								<li>
									Request that a business delete any personal data about the
									consumer that a business has collected.
								</li>

								<li>
									Request that a business that sells a consumer's personal data,
									not sell the consumer's personal data.
								</li>
							</ul>
						</div>
						<p>
							If you make a request, we have one month to respond to you. If you
							would like to exercise any of these rights, please contact us.
						</p>
						<br />
						<h2>9. GDPR Data Protection Rights</h2>
						<p>
							We would like to make sure you are fully aware of all of your data
							protection rights. Every user is entitled to the following:
						</p>
						<div className="px-4">
							<ul>
								<li>
									<h4>The right to access – </h4> You have the right to request
									copies of your personal data. We may charge you a small fee
									for this service.
								</li>
								<li>
									<h4>The right to rectification –</h4> You have the right to
									request that we correct any information you believe is
									inaccurate. You also have the right to request that we
									complete the information you believe is incomplete.
								</li>
								<li>
									<h4>The right to erasure – </h4>You have the right to request
									that we erase your personal data, under certain conditions.
								</li>
								<li>
									<h4>The right to restrict processing – </h4>You have the right
									to request that we restrict the processing of your personal
									data, under certain conditions.
								</li>
								<li>
									<h4>The right to object to processing – </h4>You have the
									right to object to our processing of your personal data, under
									certain conditions.
								</li>
								<li>
									<h4>The right to data portability –</h4> You have the right to
									request that we transfer the data that we have collected to
									another organization, or directly to you, under certain
									conditions.
								</li>
							</ul>
						</div>
						<p>
							If you make a request, we have one month to respond to you. If you
							would like to exercise any of these rights, please contact us.
						</p>
						<br />
						<h2>10. Children's Information</h2>
						<p>
							Another part of our priority is adding protection for children
							while using the internet. We encourage parents and guardians to
							observe, participate in, and/or monitor and guide their online
							activity.
						</p>

						<p>
							<a href="https://scratch-app.com/" target="blank">
								https://scratch-app.com/
							</a>{" "}
							does not knowingly collect any Personal Identifiable Information
							from children under the age of 13. If you think that your child
							provided this kind of information on our website, we strongly
							encourage you to contact us immediately and we will do our best
							efforts to promptly remove such information from our records.
						</p>
					</div>
					<Footer />
				</div>
				<img
					width={"100%"}
					height={"100%"}
					alt="lines"
					style={{ zIndex: 1 }}
					// className="position-absolute"
					src={Lines}
				/>
			</div>
		</>
	);
};
export default Policy;
