import React from "react";
import styles from "./modal.module.css";
import Teaser from "../../../assets/teaser.mp4"

const Modal = ({ setIsOpen }) => {
	return (
		<>
			<div className={styles.darkBG} onClick={() => setIsOpen(false)} />
			<div className={styles.centered}>
				<div className={styles.modal}>
					
					<div>
						<video className="video" style={{border:"solid 5px green"}} width="auto" height="50%" controls autoPlay>
							<source src={Teaser} type="Video/mp4" />
						</video>
					</div>
					
					

					<div className={styles.modalActions}>
						<div className={styles.actionsContainer}>
							<button
								className={styles.btnClose}
								onClick={() => setIsOpen(false)}>
								&times;
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Modal;












































// import React from "react";
// import styles from "./Modal.css";
// import Teaser from "../../../assets/teaser.mp4"

// const Modal = ({ setIsOpen }) => {
// 	return (
// 		<>
// 			<div className={styles.darkBG} onClick={() => setIsOpen(false)} />
// 			<div className={styles.centered}>
// 				<div className={styles.modal}>
// 					<div id="mystylevideo">
// 						<video className="video" style={{marginTop:"10%",border:"solid 7px white"}} width="auto" height="50%" controls autoPlay>
// 							<source src={Teaser} type="Video/mp4" />
// 						</video>
// 					</div>

// 					<div className={styles.modalActions}>
// 						<div className={styles.actionsContainer}>
// 							<button
// 								className={styles.deleteBtn}
// 								onClick={() => setIsOpen(false)}>
// 								Cancel
// 							</button>
// 						</div>
// 					</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

// export default Modal;
