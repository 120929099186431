import React, { useEffect } from "react";
import "../Navbar/nav.css";
import logo from "../../assets/smart-city-assets/Vector Smart logo.svg";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

import Navbar from "react-bootstrap/Navbar";

function Navbar1({ connect, account, CONFIG }) {
	useEffect(() => {
		if (
			localStorage.getItem("account") !== null &&
			CONFIG.RENTAL_CONTRACT !== ""
		) {
			connect();
		}
	}, [CONFIG.RENTAL_CONTRACT]);

	return (
		<Navbar collapseOnSelect expand="lg" variant="light">
			<Container fluid>
				<Navbar.Brand href="#home">
					<img id="navimg" className="navbar-brand" src={logo} alt="LOGO" />
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav">
					{" "}
					<i className="fa-solid text-white fa-bars"></i>{" "}
				</Navbar.Toggle>
				<Navbar.Collapse
					id="responsive-navbar-nav"
					variant="light"
					className="justify-content-end">
					<Nav>
						<button
							className="button137"
							id="btn"
							type="submit"
							onClick={() => {
								connect();
							}}>
							{account
								? `${account.slice(0, 5)}...${account.slice(
										account.length - 4
								  )}`
								: "Connect Wallet"}
						</button>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Navbar1;
