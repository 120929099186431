import React, { useState } from 'react';
import {
	FaTh,
	FaBars,
	FaRegChartBar,
	FaCommentAlt,
	FaHome,
	FaShoppingBag,
	FaThList,
	FaQuestion,
} from 'react-icons/fa';
import { BiSupport } from 'react-icons/bi';
import './Sidebar.css';
import { NavLink } from 'react-router-dom';
import '../../pages/Collection/Collection.css';
const Sidebar = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
		console.log('Enter');
	};
	const menuItem = [
		{
			path: '/',
			name: 'Home',
			icon: <FaHome size={25} />,
		},
		{
			path: '/roadmap',
			name: 'Roadmap',
			icon: <FaThList />,
		},
		{
			path: '/staking',
			name: 'Staking',
			icon: <FaCommentAlt />,
		},

		{
			path: '/policy',
			name: 'Policy',
			icon: <FaRegChartBar />,
		},
		{
			path: '/leasing',
			name: 'Leasing',
			icon: <FaShoppingBag />,
		},
		{
			path: '/about',
			name: 'About',
			icon: <FaTh />,
		},
		{
			path: '/faq',
			name: 'FAQ',
			icon: <FaQuestion />,
		},
		// {
		// 	path: '/support',
		// 	name: 'Support',
		// 	icon: <BiSupport />,
		// },
	];

	return (
		<div className='container-fluid sidebarMain'>
			<div
				style={{ width: isOpen ? '10px' : '50px' }}
				className='sidebar'
				// onMouseEnter={toggle}
				// onMouseLeave={toggle}
			>
				<div className='menu-bar' onClick={toggle}>
					<FaBars size={30} />
				</div>
				{menuItem.map((item, index) => (
					<NavLink
						to={item.path}
						key={index}
						className='link'
						activeclassName='active'
						onClick={toggle}
						style={{ borderLeft: isOpen && 'none' }}>
						<div title={item.name} className='icon' onClick={toggle}>
							{item.icon}
						</div>
						{/* <div className="link_text">{item.name}</div> */}
					</NavLink>
				))}
			</div>
			<main>{children}</main>
		</div>
	);
};

export default Sidebar;
