import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
// import ModalVideo from "react-modal-video";
import Modal from "./Modal/Modal-video";

import Lines from "../../assets/GIF/Lines.gif";
import "./about.css";
import Footer from "../../components/Footer/Footer";

function About() {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<div id="about" className="container">
				<div className="rowabout ">
					<div className="col-sm-6 mt-3">
						<div className="aboutsmart pe-3">
							<p id="aboutroboto" className="text-white">
								ABOUT SMART
								<br />
								MONEY REVOLUTION
							</p>
							<p className="text-white text-align-justify ">
								Contrary to popular belief,Lorem Ipsum is not simply random
								text. It has roots in a piece of classical Latin literature from
								45BC,making it over 2000 years old. Richard McClintock, a Latin
								professor at Hampden-Sydney Collegein Virginia,looked upon eof
								the more obscure Latin words,consectetur,from a Lorem Ipsum
								passage,
							</p>
							<p className="text-white text-align-justify ">
								<FontAwesomeIcon
									style={{ color: "#61A62D" }}
									className="mr-1"
									icon={faCircleCheck}
								/>{" "}
								Nullanonorciajusto efficitur maximus.
							</p>
							<p className="text-white text-align-justify">
								<FontAwesomeIcon
									style={{ color: "#61A62D" }}
									className="mr-1"
									icon={faCircleCheck}
								/>{" "}
								Inidantequiser attempu sluctus.
							</p>
							<p className="text-white text-align-justify">
								<FontAwesomeIcon
									style={{ color: "#61A62D" }}
									className="mr-1"
									icon={faCircleCheck}
								/>{" "}
								Duisviverranisl egetmattis gravida.
							</p>
							<div className="">
								<div className="playbtn text-white">
									<button
										className="play"
										onClick={() => setIsOpen(true)}></button>
									<p>Who We Are</p>
								</div>
								{isOpen && <Modal setIsOpen={setIsOpen} />}
							</div>
						</div>
					</div>
				</div>
			</div>
			<img
				// width={"100%"}
				// height={"90%"}
				className="bgGIF"
				alt="lines"
				// style={{ zIndex: 20 }}
				// className="position-absolute"
				src={Lines}
			/>
			<Footer />
		</>
	);
}

export default About;
