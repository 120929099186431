import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './pages/Collection/Dashboard.jsx';
import './pages/Collection/Collection.css';
import Navbar from './components/Navbar/navb';
import userContext from './context/userContext';
import Staking from './pages/Staking/stakingPage';
import Leasing from './pages/LeasingComponent/leasing';
import Policy from './pages/Policy page/Policy';
import About from './pages/About/About';
import Pagination from './components/Pagginnation';
import Faq from './pages/FAQ.jsx';
import ContactUs from './pages/ContactUs.jsx';

const App = () => {
	const [scroll, setScroll] = useState(false);

	const { connect, account, smartContract, CONFIG, error, getConfig } =
		useContext(userContext);

	useEffect(() => {
		getConfig();
	}, []);

	useEffect(() => {
		if (error) {
			alert(error);
			console.log(error);
		}
	}, [error]);
	return (
		<BrowserRouter>
			<div className='mainBody'>
				<Navbar connect={connect} account={account} CONFIG={CONFIG} />
				<Sidebar>
					<Routes>
						<Route
							path='/'
							element={
								<Dashboard
									connect={connect}
									account={account}
									smartContract={smartContract}
									CONFIG={CONFIG}
								/>
							}
						/>
						<Route
							path='/'
							element={
								<Dashboard
									connect={connect}
									account={account}
									smartContract={smartContract}
									CONFIG={CONFIG}
								/>
							}
						/>
						<Route path='/about' element={<About />} />
						<Route path='/staking' element={<Staking />} />
						<Route path='/policy' element={<Policy />} />
						<Route path='/leasing' element={<Leasing />} />
						<Route path='/roadmap' element={<Pagination />} />
						<Route path='/faq' element={<Faq />} />
						{/* <Route path='/support' element={<ContactUs />} /> */}
					</Routes>
				</Sidebar>
			</div>
		</BrowserRouter>
	);
};

export default App;
