import React from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import userContext from "../../context/userContext";
import "./stakingCard.css";

function StakingCard({ id, register }) {
	const [selected, setSelected] = useState(false);
	// let testID = 14;
	// useEffect(() => {
	// 	if (testID < 5 || testID >= 15) {
	// 		console.log("jpg");
	// 	} else if (testID >= 5 || testID < 15) {
	// 		console.log("mp4");
	// 	}
	// }, []);
	return (
		<div className={`card  ${selected ? "dark" : "light"}`}>
			{id >= 5 && id < 15 ? (
				<video
					className="video"
					width="auto"
					height="auto"
					controls
					autoPlay
					muted>
					<source
						src={`https://scratch.mypinata.cloud/ipfs/QmPEUnjWSt8jaJ4h1RbDwFGvvyLk8nj2C4Dm2fTHcbxy8C/${id}.mp4`}
						type="Video/mp4"
					/>
				</video>
			) : (
				<img
					className="card-img-top nftimage"
					src={`https://scratch.mypinata.cloud/ipfs/QmPEUnjWSt8jaJ4h1RbDwFGvvyLk8nj2C4Dm2fTHcbxy8C/${id}.jpg`}
					alt="Your NFTs"
				/>
			)}
			<div className="card-body">
				<div>
					<p className="card-text">
						Token-id : {id}
						<br />
						<input
							type="checkbox"
							className="checkbox"
							value={id}
							{...register("id")}
							onChange={(e) => {
								if (e.target.checked) {
									setSelected(true);
								} else {
									setSelected(false);
								}
							}}></input>
					</p>
				</div>
			</div>
		</div>
	);
}

export default StakingCard;
