import { useState, createContext } from "react";
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import abi from "config/abi.json";
import abi2 from "config/NFTSTAKINGabi.json";
import abi3 from "config/RentalAbi.json";
import { useEffect } from "react";
import axios from "axios";

const userContext = createContext();

export const UserProvider = ({ children }) => {
	const [account, setAccount] = useState("");
	const [smartContract, setSmartContract] = useState(null);
	const [stakingContract, setStakingContract] = useState(null);
	const [rentalContract, setRentalContract] = useState(null);
	const [web3data, setWeb3data] = useState();
	const [error, setError] = useState("");
	const [tokenids, setTokenids] = useState([]);
	const [stakedTokenids, setStakedTokenids] = useState([]);
	const [depositedTokenids, setDepositedTokenids] = useState([]);
	const [onRentTokenids, setOnRentTokenids] = useState([]);
	const [onLeaseTokenids, setOnLeaseTokenids] = useState([]);
	const [tokenIdsMinted, setTokenIdsMinted] = useState(null);
	const [staked, setStaked] = useState(false);
	const [unStaked, setUnStaked] = useState(false);
	const [deposited, setDeposited] = useState(false);
	const [rented, setRented] = useState(false);
	const [returned, setReturned] = useState(false);
	const [feedback, setFeedback] = useState(``);
	const [claimingNft, setClaimingNft] = useState("");
	const [nftSupply, setNftSupply] = useState(0);
	const [nftMinted, setNftMinted] = useState(0);

	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: "",
		STAKING_CONTRACT: "",
		RENTAL_CONTRACT: "",
		NETWORK: {
			NAME: "Ethereum Mainnet",
			SYMBOL: "ETH",
			ID: 1,
		},
		NFT_NAME: "RadioHeadz Evolution",
		SYMBOL: "scrp",
		WEI_COST: 10000000000000000,
	});

	const ethereum = window.scratchSmart;
	const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

	if (ethereum) {
		ethereum.on("accountsChanged", (accounts) => {
			setAccount(accounts[0]);
		});

		ethereum.on("chainChanged", () => {
			window.location.reload();
		});
	}

	const getConfig = async () => {
		const configResponse = await fetch("/config/config.json", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		});
		const config = await configResponse.json();
		console.log("Con", config);
		SET_CONFIG(config);
	};

	//Connect wallet

	const connect = async (callback) => {
		if (metamaskIsInstalled) {
			// await getConfig();

			Web3EthContract.setProvider(ethereum);
			let web3 = new Web3(ethereum);
			try {
				const accounts = await ethereum.request({
					method: "eth_requestAccounts",
				});
				setAccount(accounts[0]);
				localStorage.setItem("account", accounts[0]);
				alert("Connected to the wallet Successfully");

				console.log("CONFIG", CONFIG.CONTRACT_ADDRESS);
				console.log("ACCOUNT", account);

				const networkId = await ethereum.request({
					method: "net_version",
				});
				if (networkId == CONFIG.NETWORK.ID) {
					const SmartContractObj = new web3.eth.Contract(
						abi,
						CONFIG.CONTRACT_ADDRESS
					);
					const SmartContractObj2 = new web3.eth.Contract(
						abi2,
						CONFIG.STAKING_CONTRACT
					);
					const SmartContractObj3 = new web3.eth.Contract(
						abi3,
						CONFIG.RENTAL_CONTRACT
					);
					setSmartContract(SmartContractObj);
					setStakingContract(SmartContractObj2);
					setRentalContract(SmartContractObj3);

					setWeb3data(web3data);

					// if (!callback) {
					// alert("Connected to the wallet Successfully");
					// } else {
					// 	callback();
					// }
				} else {
					setError(`Change network to ${CONFIG.NETWORK.NAME}.`);
					try {
						await ethereum.request({
							method: "wallet_switchEthereumChain",
							params: [{ chainId: web3.utils.toHex(CONFIG.NETWORK.ID) }],
						});
					} catch (err) {
						console.log(err);
					}
				}
			} catch (error) {
				if (error.code === 4001) {
					alert(error.message);
				} else {
					console.error(error);
				}
				setError("Something went wrong.");
			}
		} else {
			window.location =
				"https://chrome.google.com/webstore/detail/scratch/ijpbjadcbkcilmmedjmlfiejnnakfcdd";
			alert("Please Install Scratch Wallet.");
		}
	};

	// MINT NFTS

	const totalSupply = async () => {
		if (smartContract) {
			const nftsply = await smartContract.methods.maxSupply().call();
			setNftSupply(nftsply);
			const nftminted = await smartContract.methods.totalSupply().call();
			setNftMinted(nftminted);
		}
	};

	// const { tokenids, setTokenIdsMinted } = useContext(userContext);

	const mintNFTs = async () => {
		if (!smartContract) {
			alert("Please Connect Wallet!");
			return;
		}

		if (smartContract && !ethereum._state.isUnlocked) {
			await connect(mintNFTs);
		}

		const mintedTokenId = await smartContract.methods.totalSupply().call();

		let cost;
		if (mintedTokenId < 15 || mintedTokenId >= 115) {
			cost = CONFIG.WEI_COST;
		} else if (mintedTokenId >= 15 || mintedTokenId < 115) {
			cost = CONFIG.WEI_COST2;
		}

		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(cost);
		let totalGasLimit = String(gasLimit);

		setClaimingNft(true);
		smartContract.methods
			.mint(1)
			.send({
				gasLimit: String(totalGasLimit),
				gasPrice: "40000000000",
				value: totalCostWei,
				from: account,
				to: CONFIG.CONTRACT_ADDRESS,
			})
			.once("error", (err) => {
				console.log(err);
				setFeedback("Sorry, something went wrong please try again later.");
				setClaimingNft(false);
				setTimeout(() => {
					setFeedback("");
				}, 10000);
			})
			.then((res) => {
				console.log(res);

				axios
					.post("https://scratch-app.com/api/user/create-user", {
						userAddress: `${res.from}`,
						transactionHash: `${res.transactionHash}`,
						transactionTime: new Date().toISOString(),
					})
					.then(function (response) {
						console.log("Data sent to api", response);
					})
					.catch(function (error) {
						console.log(error);
					});
				setFeedback(
					`Mint Successfull, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
				);
				setClaimingNft(false);
				setTokenIdsMinted(parseInt(mintedTokenId) + 1);
				setTimeout(() => {
					setFeedback("");
				}, 10000);
				totalSupply();
			})
			.catch((error) => {
				if (error.code === 4001) {
					alert(error.message);
				} else {
					console.error(error);
				}
			});
	};

	//Get Users Nft Token Ids

	const getTokenIds = async () => {
		await smartContract.methods
			.walletOfOwner(account)
			.call()
			.then((res) => {
				setTokenids(res.map((item) => parseInt(item)));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	//Get Users staked Nft Token Ids

	const getStakedTokenIds = async () => {
		await stakingContract.methods
			.tokensOfOwner(account)
			.call()
			.then((res) => {
				setStakedTokenids(res.map((item) => parseInt(item)));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	//Get Available Nft Token Ids for rent

	const getLeaseAvailableToken = async () => {
		await rentalContract.methods
			.tokensAvailableForLease()
			.call()
			.then((res) => {
				setDepositedTokenids(res.map((item) => parseInt(item)));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	//Get given for rent Nft Token Ids

	const getOnLeaseToken = async () => {
		await rentalContract.methods
			.tokensOfOwner(account)
			.call()
			.then((res) => {
				setOnLeaseTokenids(res.map((item) => parseInt(item)));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	//Get already rent Nft Token Ids for leasing

	const getRentedTokenIds = async () => {
		await rentalContract.methods
			.rentalTokensOfOwner(account)
			.call()
			.then((res) => {
				setOnRentTokenids(res.map((item) => parseInt(item)));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	// useEffect(() => {
	// 	getRentedTokenIds();

	// 	getReturnDueDate();
	// }, []);
	useEffect(() => {
		if (smartContract != "" && account !== "" && rentalContract) {
			getTokenIds();
			getStakedTokenIds();
			getLeaseAvailableToken();
			getRentedTokenIds();
			getOnLeaseToken();
		}
	}, [smartContract]);

	useEffect(() => {
		getTokenIds();
		getStakedTokenIds();
	}, [rentalContract]);
	useEffect(() => {
		getTokenIds();
		getStakedTokenIds();
	}, [staked]);
	useEffect(() => {
		getTokenIds();
		getStakedTokenIds();
	}, [unStaked]);

	useEffect(() => {
		getTokenIds();
		getLeaseAvailableToken();
	}, [deposited]);

	useEffect(() => {
		getTokenIds();
		getLeaseAvailableToken();
		// getReturnDueDate();
		getRentedTokenIds();
	}, [rented]);
	useEffect(() => {
		getTokenIds();
		getLeaseAvailableToken();
		// getReturnDueDate();
		getRentedTokenIds();
	}, [returned]);

	useEffect(() => {
		totalSupply();
		getConfig();
	}, []);

	useEffect(() => {
		if (smartContract !== "" && account !== "") {
			totalSupply();
		}
	}, [account, smartContract]);

	return (
		<userContext.Provider
			value={{
				connect,
				account,
				mintNFTs,
				totalSupply,
				nftMinted,
				nftSupply,
				smartContract,
				stakingContract,
				rentalContract,
				web3data,
				error,
				getConfig,
				setTokenIdsMinted,
				tokenIdsMinted,
				CONFIG,
				depositedTokenids,
				staked,
				setStaked,
				unStaked,
				setUnStaked,
				deposited,
				setDeposited,
				rented,
				setRented,
				returned,
				setReturned,
				tokenids,
				stakedTokenids,
				onRentTokenids,
				onLeaseTokenids,
				getTokenIds,
				getStakedTokenIds,
			}}>
			{children}
		</userContext.Provider>
	);
};

export default userContext;
