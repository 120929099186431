import React, { useContext } from "react";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import "./styles.css";
import Lines from "../../assets/GIF/Lines.gif";
// import image1 from "../../../src/images/nft1.jpeg";
// import image1 from "../../../"
import image1 from "../../assets/4.jpeg";
import image2 from "../../assets/2.jpeg";
import image3 from "../../assets/5.jpeg";
import image4 from "../../assets/3.jpeg";
import Footer from "../Footer/Footer";
import userContext from "../../context/userContext";

function Pagination() {
	const { mintNFTs } = useContext(userContext);

	return (
		<>
			<div className=" container-fluid text-center">
				<div className="Container12">
					<div className="testAbout d-flex py-2">
						<div className="profile-card me-3">
							<div className="banner-card">
								<img src={image4} alt="" className="profile-bg" />
								<h1 className="percentage">10%</h1>
							</div>

							<div className="caption">
								<h5> Lorum ipsum dolor </h5>
								<p>
									lorem ipsum dolor sit amet. consetetur sadipscing elitr, sed
									diam nonumy eirmod tempor invidiunt ut labore et dalore mgna
									aliquuyam erat sed diam
								</p>
							</div>
						</div>
						<div className="profile-card me-3">
							<div className="banner-card">
								<img src={image1} alt="" className="profile-bg" />
								<h1 className="percentage">25%</h1>
							</div>

							<div className="caption">
								<h5> Lorum ipsum dolor </h5>
								<p>
									lorem ipsum dolor sit amet. consetetur sadipscing elitr, sed
									diam nonumy eirmod tempor invidiunt ut labore et dalore mgna
									aliquuyam erat sed diam
								</p>
							</div>
						</div>
						<div className="profile-card me-3">
							<div className="banner-card">
								<img src={image2} alt="" className="profile-bg" />
								<h1 className="percentage">50%</h1>
							</div>

							<div className="caption">
								<h5> Lorum ipsum dolor </h5>
								<p>
									lorem ipsum dolor sit amet. consetetur sadipscing elitr, sed
									diam nonumy eirmod tempor invidiunt ut labore et dalore mgna
									aliquuyam erat sed diam
								</p>
							</div>
						</div>
						<div className="profile-card me-3">
							<div className="banner-card">
								<img src={image3} alt="" className="profile-bg" />
								<h1 className="percentage">75%</h1>
							</div>

							<div className="caption">
								<h5> Lorum ipsum dolor </h5>
								<p>
									lorem ipsum dolor sit amet. consetetur sadipscing elitr, sed
									diam nonumy eirmod tempor invidiunt ut labore et dalore mgna
									aliquuyam erat sed diam
								</p>
							</div>
						</div>
						<div className="profile-card me-3">
							<div className="banner-card">
								<img src={image2} alt="" className="profile-bg" />
								<h1 className="percentage">100%</h1>
							</div>

							<div className="caption">
								<h5> Lorum ipsum dolor </h5>
								<p>
									lorem ipsum dolor sit amet. consetetur sadipscing elitr, sed
									diam nonumy eirmod tempor invidiunt ut labore et dalore mgna
									aliquuyam erat sed diam
								</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="btn-mint">
						<button className="button1" onClick={mintNFTs}>
							Mint Now
						</button>
					</div>
				</div>
			</div>

			<div>
				<img src={Lines} alt="lines" className="roadmap-gif" />
				<Footer />
			</div>
		</>
	);
}

export default Pagination;
