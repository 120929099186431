import React, { useContext, useState, useEffect } from "react";
import "./Collection.css";
import img1 from "../../assets/4.jpeg";
import img2 from "../../assets/3.jpeg";
import img3 from "../../assets/2.jpeg";
import img4 from "../../assets/5.jpeg";
import axios from "axios";
import userContext from "../../context/userContext";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

import Lines from "./GIF/Lines.gif"

const Dashboard = () => {


	const {  mintNFTs,nftMinted,nftSupply,getConfig } = useContext(userContext);
	useEffect(()=>{
		getConfig();
	},[])
	
	return (
		<div >
			<img
					width={"100%"}
					height={"50%"}
					alt="lines"
					style={{ zIndex: -1 }}
					src={Lines}
					// src={require(`./Lines_BG/lines_scratch120.png`)}
					className='dashimg1'
				/>
			<div className="row collection-section-container">

				<div className="col-sm-12 col-md-6">
					<div className="text-content mt-4">
						<div className="text-heading">
							<h1>CRAZY</h1>
							<h1>MINTING</h1> 
							<h1>NFT</h1>
							<h1>COLLECTION</h1>
						</div>
						<div className="sub-heading">
							<h3>
								{nftMinted}/{nftSupply} MINTED
							</h3>
						</div>
						<div className="description">
							<p>Max 10 nft per wallet. price 0.2 ETH + GAS</p>
							<p>Mint is live until{" "}&nbsp;<span style={{ color: "#61A62D" }}> 23 apr 04:00H</span>
							</p>
						</div>
					</div>
					<div className="button-section">
						<button
							className="mintbutton"
							onClick={() => {
								mintNFTs();
							}}>
							MINT NOW
						</button>
						<Link to="/roadmap">
							<button className="roadmap-button">
								ROADMAP
							</button>
						</Link>
					</div>
				</div>
				<div className="col-sm-12 col-md-6 ">
					<div className="row flex-sm-column flex-md-row mt-4 img-content">
						<div className="col-sm-12 col-md-6 mt-3 justify-contenter-center align-items-center">
							<img
								src={img1}
								className="img5 img-content1"
								alt="imge"
								
							/>
						</div>
						<div className="col-sm-12 col-md-6 mt-3 justify-contenter-center align-items-center">
							<img
								src={img2}
								className="img5 img-content2"
								alt="imge"
								
							/>
						</div>
					</div>
					<div className="row flex-sm-column flex-md-row flex-lg-row mt-4 img-content">
						
						<div className="col-sm-12 col-md-6 mt-3 justify-contenter-center align-items-center">
							<img
								src={img3}
								className="img5 img-content1"
								alt="imge"
								
							/>
						</div>
						<div className="col-sm-12 col-md-6 mt-3 justify-contenter-center align-items-center">
							<img
								src={img4}
								className="img5 img-content2"
								alt="imge"
								
							/>
						</div>
					</div>
				</div>
			{/* <Footer /> */}

			</div>
			<Footer />
				<img
					width={"100%"}
					height={"50%"}
					alt="lines"
					style={{ zIndex: -1 }}
					src={Lines}
					className='dashimg img-lines'
				/>

		</div>
	);
};

export default Dashboard;

