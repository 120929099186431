import React from 'react';
import {
	MDBCol,
	MDBContainer,
	MDBIcon,
	MDBRow,
	MDBTypography,
} from 'mdb-react-ui-kit';
import Footer from '../components/Footer/Footer';

export default function Faq() {
	return (
		<>
			<MDBContainer>
				<section>
					<div class='container mb-5'>
						<div class='row'>
							<div class='col-lg-12 d-flex align-items-center justify-content-center'>
								<div class='section-heading '>
									<h2 className='contactUsText text-center fw-bold'>
										Get in Touch for any support
									</h2>
									<p className='text-white  text-center ms-auto mt-4'>
										Email:
										<span
											onClick={() =>
												(window.location = 'mailto:support@scratch-app.com')
											}
											style={{
												marginLeft: '5px',
												cursor: 'pointer',
												color: '#65b82e',
											}}>
											support@scratch-app.com
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
					<MDBTypography
						style={{ color: '#65b82e' }}
						tag='h3'
						className='text-center mb-4 pb-2 fw-bold'>
						FAQ
					</MDBTypography>
					<p className='text-center text-white mb-5'>
						Find the answers for the most frequently asked questions below
					</p>

					<MDBRow>
						<MDBCol md='6' lg='6' className='mb-4'>
							<MDBTypography
								style={{ color: '#7bdc3a' }}
								tag='h5'
								className='mb-3'>
								<MDBIcon far icon='paper-plane pe-2' /> How do I protect my
								crypto assets in Scratch Wallet?
							</MDBTypography>
							<p style={{ color: 'white' }}>
								To protect your crypto assets in Scratch Wallet, make sure to
								keep your private keys secure and never share them with anyone.
								Enable additional security measures such as two-factor
								authentication if available. Regularly update your software and
								use secure internet connections when accessing your wallet.
							</p>
						</MDBCol>
						<MDBCol md='6' lg='6' className='mb-4'>
							<MDBTypography
								style={{ color: '#7bdc3a' }}
								tag='h5'
								className='mb-3'>
								<MDBIcon fas icon='pen-alt pe-2' /> Can I recover my funds if I
								lose access to my Scratch Wallet?
							</MDBTypography>
							<p style={{ color: 'white' }}>
								It is crucial to back up your seed phrase or recovery phrase
								securely. In case you lose access to your wallet, you can
								restore it using the seed phrase. Make sure to store this
								information in a secure and separate location.
							</p>
						</MDBCol>
						<MDBCol md='6' lg='6' className='mb-4'>
							<MDBTypography
								style={{ color: '#7bdc3a' }}
								tag='h5'
								className='mb-3 '>
								<MDBIcon fas icon='user pe-2' /> Are my transactions in Scratch
								Wallet anonymous?
							</MDBTypography>
							<p style={{ color: 'white' }}>
								While Scratch Wallet prioritizes user privacy and security,
								transactions on the blockchain are public and can be traced back
								to the wallet addresses involved. It is essential to be mindful
								of the details you share and consider the privacy features of
								the cryptocurrencies you are using.
							</p>
						</MDBCol>
						<MDBCol md='6' lg='6' className='mb-4'>
							<MDBTypography
								style={{ color: '#7bdc3a' }}
								tag='h5'
								className='mb-3'>
								<MDBIcon fas icon='rocket pe-2' /> What should I do if I suspect
								unauthorized activity in my Scratch Wallet?
							</MDBTypography>
							<p style={{ color: 'white' }}>
								While Scratch Wallet prioritizes user privacy and security,
								transactions on the blockchain are public and can be traced back
								to the wallet addresses involved. It is essential to be mindful
								of the details you share and consider the privacy features of
								the cryptocurrencies you are using.
							</p>
						</MDBCol>
					</MDBRow>
				</section>
			</MDBContainer>
			<Footer />
		</>
	);
}
